<template>
  <v-card v-if="roles != null" elevation="0">
    <v-toolbar height="80" elevation="0">
      <div class="font-size-h3 text-primary">Select Role</div>
    </v-toolbar>

    <perfect-scrollbar
    v-if="ready"
      class="scroll"
      style="max-height:calc(100vh - 80px); position: relative"
    >
      <v-list>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item v-for="item in roles" :key="item.id">
            <v-list-item-icon>
              <unicon :name="setIcon(item.name)" :fill="'#0971CE'"></unicon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <div
                style="border-radius:5px; display:flex; min-width:25px; min-height:25px;justify-content: flex-end;align-items: center;"
                class="text-primary p-1"
              >
                {{ item.users.length }}
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </perfect-scrollbar>
    <v-progress-circular
    v-else
    class="mt-5"
      indeterminate
      color="primary"
    ></v-progress-circular>
  </v-card>
</template>

<script>
export default {
  name:"roleList",
  components: {},
  data() {
    return {
      ready: false,
      roles: null,
      selectedItem: 0,
    };
  },

  created() {},
  watch: {
    selectedItem(newselection) {
      this.$root.admin.selectedRole = this.roles[newselection].id;
    },
  },
  methods: {
    setIcon(type) {
      switch (type.toLowerCase()) {
        case "administrator": {
          return "key-skeleton";
        }
        case "doctor": {
          return "user-md";
        }
        case "patient": {
          return "user";
        }
        case "manager": {
          return "user-plus";
        }
        case "kit_user": {
          return "bag";
        }
        default:
          return "comment-alt-question";
      }
    },
  },
  async mounted() {
    this.ready = false;
    this.roles = await this.$user.getRoles();
    this.ready = true;
  },
};
</script>

<style></style>
